import Vue from 'vue';
import Vuex from 'vuex';
import config from 'config';

declare let isSWAvailable: () => boolean;

if (isSWAvailable()) {
  // Register the service worker in builds supporting one
  import('./register').then((m) => m.registerSW(config.zento.sw.resource, config.zento.sw.registration));
}

export async function normalizeEnvironment() {
  // Ensure Vuex binding before continue
  Vue.use(Vuex);

  const createApp = await import('@vue-storefront/core/app').then((m) => m.createApp);
  const registerSyncTaskProcessor = await import('@vue-storefront/core/lib/sync/task').then(
    (m) => m.registerSyncTaskProcessor,
  );
  const onNetworkStatusChange = await import(
    '@vue-storefront/core/modules/offline-order/helpers/onNetworkStatusChange'
  ).then((m) => m.onNetworkStatusChange);
  const currentStoreView = await import('@vue-storefront/core/lib/multistore').then((m) => m.currentStoreView);
  const defaultGuardFactory = await import('./default.navigationGuard').then((m) => m.defaultGuardFactory);
  const mountIfPossible = await import('./mount').then((m) => m.mountIfPossible);

  return {
    createApp,
    registerSyncTaskProcessor,
    onNetworkStatusChange,
    currentStoreView,
    config,
    defaultGuardFactory,
    mountIfPossible,
  };
}
